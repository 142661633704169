import React, { useMemo } from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { useQueryParam, StringParam } from "use-query-params"
import { withPreview } from "gatsby-source-prismic"

import PageWrapper from "../components/page-wrapper"
import SEO from "../components/seo"
import Grid from "../components/grid"
import SubNav from "../components/sub-nav"
import LegalBody from "../components/legal-body"

export const query = graphql`
  query Legal {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicLegal {
      edges {
        node {
          uid
          prismicId
          data {
            name
            text {
              raw
            }
          }
        }
      }
    }
  }
`

const LegalPage = (props) => {
  const [filter = "terms-of-service", setFilter] = useQueryParam(
    "filter",
    StringParam
  )

  const legals = get(props, "data.allPrismicLegal.edges")
  const page = useMemo(
    () =>
      legals.map((item) => item.node).filter((item) => item.uid === filter)[0],
    [filter]
  )

  return (
    <PageWrapper name="legal">
      <SEO title="Legal" />
      <Grid>
        <SubNav data={legals} path="/legal" filter={filter} />
        {page && <LegalBody data={page.data.text} />}
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(LegalPage)
