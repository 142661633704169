import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { Container } from "./legal-body.styles"

export default function LegalBody({ data }) {
  return (
    <Container>
      <RichText
        render={data.raw}
        htmlSerializer={htmlSerializer()}
        linkResolver={linkResolver}
      />
    </Container>
  )
}
