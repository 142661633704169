import styled from "styled-components"
import { compose, space, color } from "styled-system"
import { Link } from "gatsby"
import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)`
  ${compose(color)}

  position: sticky;
  z-index: 10;
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  border-bottom: 0.1rem solid ${({ isSticky }) => (isSticky ? "#fff" : "#000")};
  box-shadow: ${({ isSticky }) =>
    isSticky ? "0 0 10px rgba(0, 0, 0, 0.45)" : "none"};
  -webkit-overflow-scrolling: touch;
  transition: border 200ms ease;
  touch-action: pan-x;

  @media screen and (min-width: 40em) {
    box-shadow: none;
  }
`
Container.defaultProps = {
  top: ["y.mobile.2.5", "-1px"],
  gridColumn: "full-start / full-end",
  mt: 0,
  mb: ["y.mobile.2.0", "y.desktop.2.0"],
  bg: "white",
}

export const IsStickyHelper = styled.div`
  height: 1px;
  visibility: hidden;
`

export const Inner = styled.div`
  ${space}

  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  float: left;
  box-sizing: border-box;

  &::before,
  &::after {
    flex: 1;
    content: "";
  }
`
Inner.defaultProps = {
  px: "x.0",
}

export const NavItem = styled(Text)`
  white-space: nowrap;
  text-transform: lowercase;
  cursor: pointer;

  ::selection {
    color: ${(props) => props.theme.colors.black[0]};
    background: transparent;
  }

  &.active {
    color: ${(props) => props.theme.colors.black[6]} !important;
  }
`
NavItem.defaultProps = {
  as: Link,
  fontFamily: "mono",
  fontSize: ["mobile.-1", "desktop.-1"],
  mx: ["x.2", "x.3"],
  color: "black.0",
  lineHeight: ["mobile.2.0", "desktop.2.0"],
}
