import React from "react"
import { useInView } from "react-intersection-observer"

import { Container, Inner, NavItem, IsStickyHelper } from "./sub-nav.styles"

export default ({ data, path, filter, showAll }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  })

  return (
    <>
      <IsStickyHelper ref={ref} />
      <Container isSticky={!inView}>
        <Inner>
          {showAll && (
            <NavItem to={path} className={!filter && "active"}>
              All
            </NavItem>
          )}
          {data.map(({ node }) => (
            <NavItem
              key={node.uid}
              to={`${path}?filter=${node.uid}`}
              className={filter === node.uid && "active"}
            >
              {node.data.name}
            </NavItem>
          ))}
        </Inner>
      </Container>
    </>
  )
}
